<template>
    <div>
        <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
            <thead>
               <tr>
                    <th width="250px">{{ $t('items.item_details') }}</th>
                    <th width="300px">
                    تاريخ البداية     
                    </th >
                    <th width="300px">
                        تاريخ النهاية     
                    </th>
                    <th >{{ $t('notes') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in items_list" :key="index" :class="getClassValidationRepeaterByIndex(index)">
                    <td>
                        <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                        <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                        <div class="pl-2 pr-2 text-dark">
                            <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                            <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                            <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                            <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                            <p class="mb-1" v-if="row.item.available_stock_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.available_stock_qty }}</p>
                        </div>
                        </a>
                    </td>
                    <td width="250px">
                        <input type="datetime-local" :value="row.start_date" @input="(e)=>onStartDateChange(e.target.value,index)" class="form-control mt-4" />
                    </td>
                    <td width="250px">
                        <input type="datetime-local" :value="row.end_date" class="form-control mt-4"  @input="(e)=>onEndDateChange(e.target.value,index)" />
                    </td>
                    <td >
                        <textarea class="w-100 border" rows="4" cols="50" :value="row.note"  @input="(e)=>onNoteChange(e.target.value,index)"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name:'RentDetailsTable',
        props:{
            items_list:{
                type:Array,
                default:[]
            },
            repeater_validation:{
                type:Array,
                default:[]
            }
        },
        methods:{
            getClassValidationRepeaterByIndex(index) {
                if (index != null) {
                    if (this.repeater_validation.includes(index))
                    return 'tr-validation-error';
                }
                return '';
            },
            onStartDateChange(value, index){
                this.$emit('onInputChange', {index, value, key:'start_date'})
            },
            onEndDateChange(value, index){
                this.$emit('onInputChange', {index, value, key:'end_date'})
            },
            onNoteChange(value, index){
                this.$emit('onInputChange', {index, value, key:'note'})
            },
        }
    }
</script>
